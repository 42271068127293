.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  overflow-y: scroll;
}

/* width */
body::-webkit-scrollbar {
  width: 0px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  overflow-y: scroll;
}

/* width */
.styled-scrollbar::-webkit-scrollbar {
  width: 3px;
  border-radius: 22px;
}

/* Track */
.styled-scrollbar::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
.styled-scrollbar::-webkit-scrollbar-thumb {
  background: #A9A7FE;
}

/* Handle on hover */
.styled-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.styled-link a {
  color: #A9A7FE;
  text-decoration: underline;
}

.disableSelection{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
